import { get, post, put } from "@/application/api";
import { AxiosResponse } from "axios";

export interface AddressBookAddress {
  id: number;
  clientId: number;
  invoiceAddress: {
    companyName: string;
    department: string;
    firstName: string;
    lastName: string;
    country: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    isWithoutPostalCode: boolean;
    street: string;
    addressLine: string;
    city: string;
    address: string;
    email: string;
    phone: string;
  };
  useDeliveryAsInvoiceAddress: boolean;
  deliveryAddress: {
    companyName: string;
    department: string;
    firstName: string;
    lastName: string;
    country: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    isWithoutPostalCode: boolean;
    street: string;
    addressLine: string;
    city: string;
    address: string;
    email: string;
    phone: string;
  };
}

export const save = ({
  addressBookAddress,
  clientId,
}: {
  addressBookAddress: Partial<AddressBookAddress>;
  clientId: number;
}): Promise<AxiosResponse> =>
  post(`client/${clientId}/address-book`, addressBookAddress);

export const saveById = ({
  addressBookAddressId,
  addressBookAddress,
  clientId,
}: {
  addressBookAddress: Partial<AddressBookAddress>;
  addressBookAddressId: number;
  clientId: number;
}): Promise<AxiosResponse> =>
  put(
    `client/${clientId}/address-book/${addressBookAddressId}`,
    addressBookAddress
  );

export const addressBookAddressShow = ({
  clientId,
  addressBookAddressId,
}: {
  clientId: number;
  addressBookAddressId: number;
}): Promise<
  AxiosResponse<{
    data: AddressBookAddress;
    isManual?: boolean;
    validationErrors: { message: string; errors: Record<string, string[]> };
  }>
> => get(`client/${clientId}/address-book/${addressBookAddressId}`);


import Vue from "vue";
import KForm from "@/components/KForm.vue";
import eventBus from "@/application/eventBus";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import {
  AddressBookAddress,
  addressBookAddressShow,
  save,
  saveById,
} from "@/modules/addressbook/api/addressBookAddressSave";
import CountryAutocomplete from "@/modules/salesOrder/components/CountryAutocomplete.vue";
import { getAddressInfo } from "@/modules/salesOrder/api/addressInfo";

interface ComponentData {
  isLoading: boolean;
  values: Partial<AddressBookAddress> | null;
  isLoadingSave: boolean;
  isFormDirty: boolean;
}

export default Vue.extend({
  name: "addressBookAddressForm",
  components: {
    KCheckbox,
    KTextField,
    RequiredClientDialog,
    CountryAutocomplete,
    KForm,
  },
  data: (): ComponentData => ({
    isLoading: true,
    values: null,
    isFormDirty: false,
    isLoadingSave: false,
  }),
  created() {
    this.resetAddress();
  },
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    isUpdateForm(): boolean {
      return this.$route.params.addressBookAddressId !== undefined;
    },
    useDutchAddressForInvoice(): boolean {
      if (!this.values) return true;
      return this.countryIsNLorBE(this.values.invoiceAddress.country);
    },
    useDutchAddressForDelivery(): boolean {
      if (!this.values) return true;
      return this.countryIsNLorBE(this.values?.deliveryAddress.country);
    },
    clientId(): number {
      if (this.isUpdateForm) {
        return parseInt(this.$route.params.clientId as string);
      }
      return this.client?.id;
    },
  },
  watch: {
    "$route.params.addressBookAddressId": {
      immediate: true,
      async handler(id: number): Promise<void> {
        if (id !== undefined) {
          try {
            this.isLoading = true;
            const response = await addressBookAddressShow({
              clientId: this.clientId,
              addressBookAddressId: id,
            });
            if (response.data.validationErrors) {
              setTimeout(() => {
                Object.keys(response.data.validationErrors.errors).forEach(
                  (key) => {
                    this.addValidationErrorToStore({
                      key,
                      message: response.data.validationErrors.errors[key][0],
                    });
                  }
                );
                (this.$refs.form as any).$refs.form.validate();
              }, 500);
            }

            this.resetAddress();
            this.values = { ...this.values, ...response.data.data };
            this.$nextTick(() => {
              this.isFormDirty = false;
            });
          } catch (error: any) {
            if (error.response?.status === 404) {
              this.$router.push({ name: "notFound" });
              return;
            }
            throw error;
          }
        }
        this.isLoading = false;
      },
    },
    values: {
      deep: true,
      handler(): void {
        this.isFormDirty = true;
      },
    },
    "values.useDeliveryAsInvoiceAddress"() {
      if (!this.values) return;
      if (this.values.useDeliveryAsInvoiceAddress) {
        this.values.invoiceAddress = this.values.deliveryAddress;
      }
    },
    "client.id": {
      handler(newValue: number, oldValue: number): void {
        if (!oldValue) return;
        this.$router.push({
          name: "addressBook.index",
        });
      },
    },
    "values.deliveryAddress": {
      handler() {
        if (!this.values) return;
        if (this.values.useDeliveryAsInvoiceAddress) {
          this.values.invoiceAddress = this.values.deliveryAddress;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("error", { addValidationErrorToStore: "add" }),
    resetAddress() {
      this.values = {
        invoiceAddress: {
          companyName: "",
          department: "",
          firstName: "",
          lastName: "",
          country: "",
          postalCode: "",
          houseNumber: "",
          houseNumberAddition: "",
          isWithoutPostalCode: false,
          street: "",
          addressLine: "",
          city: "",
          address: "",
          email: "",
          phone: "",
        },
        useDeliveryAsInvoiceAddress: true,
        deliveryAddress: {
          companyName: "",
          department: "",
          firstName: "",
          lastName: "",
          country: "",
          postalCode: "",
          houseNumber: "",
          houseNumberAddition: "",
          isWithoutPostalCode: false,
          street: "",
          addressLine: "",
          city: "",
          address: "",
          email: "",
          phone: "",
        },
      };
    },
    countryIsNLorBE(country: string): boolean {
      return ["NL", "BE"].includes(country);
    },
    async save() {
      this.isLoadingSave = true;
      try {
        if (!this.values) {
          return;
        }
        await this.ifUpdateFormSave();
        await this.ifCreateFormSave();
        eventBus.$emit("snackbar", this.$t("global.saveSuccess"));
        this.isFormDirty = false;
        this.redirectToTable();
      } catch (e) {
        eventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("global.error"),
        });
        throw e;
      }
      this.isLoadingSave = false;
    },
    async fillAddressByPostalCode(
      parent:
        | AddressBookAddress["invoiceAddress"]
        | AddressBookAddress["deliveryAddress"]
    ) {
      try {
        const { postalCode, houseNumber, houseNumberAddition } = parent;
        const response = await getAddressInfo({
          postalCode,
          houseNumber,
          houseNumberAddition,
        });
        parent.city = response.data.data.city;
        parent.street = response.data.data.street;
      } catch (e) {
        return;
      }
    },
    async ifUpdateFormSave() {
      if (this.isUpdateForm) {
        await saveById({
          addressBookAddress: this.values,
          clientId: this.clientId,
          addressBookAddressId: parseInt(
            this.$route.params.addressBookAddressId
          ),
        });
      }
    },
    async ifCreateFormSave() {
      if (!this.isUpdateForm) {
        await save({
          addressBookAddress: this.values,
          clientId: this.clientId,
        });
      }
    },
    handleSuccess() {
      eventBus.$emit("snackbar", this.$t("addressBook.messages.saveSuccess"));
      this.redirectToTable();
    },
    redirectToTable() {
      if (!(this.$route.query.add as string[])) {
        this.$router.push({
          name: "addressBook.index",
        });
      }
    },
  },
});
